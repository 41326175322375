<template>
  <div class="customer-layout">
    <AppHeader title="Customer Portal" />
    
    <!-- Main Content -->
    <main class="main-content">
      <div class="service-grid">
        <div class="service-card" @click="navigateTo('solar')">
          <i class="fas fa-solar-panel"></i>
          <span>Solar Install</span>
        </div>
        <div class="service-card" @click="navigateTo('hvac')">
          <i class="fas fa-wind"></i>
          <span>HVAC Install</span>
        </div>
        <div class="service-card" @click="navigateTo('service')">
          <i class="fas fa-tools"></i>
          <span>After Service</span>
        </div>
        <div class="service-card" @click="navigateTo('tracking')">
          <i class="fas fa-project-diagram"></i>
          <span>Project Tracking</span>
        </div>
        <div class="service-card" @click="navigateTo('invoices')">
          <i class="fas fa-file-invoice-dollar"></i>
          <span>Invoice & Reports</span>
        </div>
        <div class="service-card" @click="navigateTo('other')">
          <i class="fas fa-ellipsis-h"></i>
          <span>Other Services</span>
        </div>
      </div>

      <!-- Live Chat Widget -->
      <div class="chat-widget">
        <button class="chat-button">
          <i class="fas fa-comments"></i>
          Chat with Support
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'CustomerPortal',
  components: {
    AppHeader
  }
}
</script>

<style scoped>
.customer-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.top-nav {
  height: 64px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 300px;
}

.search-container input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
}

.search-container button {
  padding: 0.5rem;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.nav-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-btn {
  background: none;
  border: none;
  font-size: 1.25rem;
  color: #666;
  cursor: pointer;
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.main-content {
  flex: 1;
  padding: 2rem;
  position: relative;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.service-card i {
  font-size: 2.5rem;
  color: #4285f4;
}

.service-card span {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.chat-widget {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.chat-button {
  padding: 1rem 2rem;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.chat-button:hover {
  background-color: #3367d6;
}

.logout-btn {
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-nav {
    padding: 0 1rem;
  }
  
  .left-section {
    gap: 1rem;
  }
  
  .search-container {
    display: none;
  }
  
  .main-content {
    padding: 1rem;
  }
  
  .service-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .service-card {
    padding: 1rem;
  }
}
</style> 