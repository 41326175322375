import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ECharts from 'vue-echarts'
import 'echarts'
import { useAuthStore } from '@/stores/auth'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.component('v-chart', ECharts)

// axios 配置
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Accept'] = 'application/json'

// axios 拦截器
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // 添加调试日志
  console.log('Request Config:', {
    url: config.url,
    method: config.method,
    headers: config.headers
  })
  return config
}, error => {
  console.error('Request error:', error)
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  console.error('Response error:', error)
  if (error.response?.status === 401) {
    const authStore = useAuthStore()
    authStore.logout()
    router.push('/login')
  }
  return Promise.reject(error)
})

// 初始化认证状态
const authStore = useAuthStore()
authStore.initialize()

app.mount('#app')
