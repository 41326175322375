import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import Workplace from '@/views/Workplace.vue'
import Leads from '@/views/crm/Leads.vue'
import Proposals from '@/views/crm/Proposals.vue'
import Deals from '@/views/crm/Deals.vue'
import Meeting from '@/views/crm/Meeting.vue'
import Stock from '@/views/logistics/Stock.vue'
import Orders from '@/views/logistics/Orders.vue'
import Contracts from '@/views/template/Contracts.vue'
import Invoices from '@/views/template/Invoices.vue'
import Active from '@/views/projects/Active.vue'
import History from '@/views/projects/History.vue'
import PresentProposal from '@/views/crm/PresentProposal.vue'
import UserLogin from '../views/user/Login.vue'  // 更新导入名称
import Customer from '@/views/Customer.vue'  // 确保创建了这个视图组件

const routes = [
  {
    path: '/login',
    name: 'UserLogin',  // 更新路由名称
    component: UserLogin
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/customer',
    name: 'customer',
    component: Customer,
    meta: { requiresAuth: true }  // 需要认证才能访问
  },
  {
    path: '/workplace',
    name: 'Workplace',
    component: Workplace,
    children: [
      {
        path: 'crm/leads',
        name: 'Leads',
        component: Leads
      },
      {
        path: 'crm/proposals',
        name: 'Proposals',
        component: Proposals
      },
      {
        path: 'crm/deals',
        name: 'Deals',
        component: Deals
      },
      {
        path: 'crm/meeting',
        name: 'Meeting',
        component: Meeting
      },
      {
        path: 'crm/present-proposal',
        name: 'PresentProposal',
        component: PresentProposal
      },
      {
        path: 'crm/compare-proposals',
        name: 'CompareProposals',
        component: () => import('@/views/crm/CompareProposals.vue')
      },
      {
        path: 'logistics/stock',
        name: 'Stock',
        component: Stock
      },
      {
        path: 'logistics/orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: 'template/contracts',
        name: 'Contracts',
        component: Contracts
      },
      {
        path: 'template/invoices',
        name: 'Invoices',
        component: Invoices
      },
      {
        path: 'projects/active',
        name: 'ActiveProjects',
        component: Active
      },
      {
        path: 'projects/history',
        name: 'ProjectHistory',
        component: History
      },
      {
        path: 'zoho/zoho-data-upload',
        name: 'ZohoDataUpload',
        component: () => import('@/views/zoho/ZohoDataUpload.vue')
      },

      {
        path: 'crm/battery-simulation',
        name: 'BatterySimulation',
        component: () => import('@/views/crm/BatterySimulation.vue'),
        meta: {
          title: 'Battery Simulation',
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: '/',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const token = localStorage.getItem('token')

  if (authRequired && !token) {
    return next('/login')
  }

  const authStore = useAuthStore()
  const isAuthenticated = authStore.isAuthenticated
  const isStaff = authStore.currentUser?.is_staff

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login')
    return
  }

  if (to.meta.requiresStaff && !isStaff) {
    next('/customer')
    return
  }

  if (to.path === '/login' && isAuthenticated) {
    next(isStaff ? '/dashboard' : '/customer')
    return
  }
  document.title = to.meta.title || 'Polaron'
  next()
})

export default router 