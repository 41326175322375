import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    token: null,
    isAuthenticated: false
  }),

  actions: {
    setUser(user) {
      this.user = user
      this.isAuthenticated = !!user
    },
    setToken(token) {
      this.token = token
    },
    logout() {
      this.user = null
      this.token = null
      this.isAuthenticated = false
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    },
    initialize() {
      const storedToken = localStorage.getItem('token')
      const storedUser = localStorage.getItem('user')
      
      if (storedToken && storedUser) {
        try {
          this.token = storedToken
          this.user = JSON.parse(storedUser)
          this.isAuthenticated = true
        } catch (e) {
          console.error('Error initializing auth store:', e)
          this.logout()
        }
      }
    }
  },

  getters: {
    currentUser: (state) => state.user,
    authToken: (state) => state.token,
    isStaff: (state) => state.user?.is_staff || false
  }
}) 