<template>
  <div class="google-login">
    <div id="g_id_onload"
         :data-client_id="clientId"
         data-context="signin"
         data-ux_mode="popup"
         data-callback="handleCredentialResponse"
         data-auto_prompt="false">
    </div>

    <div class="g_id_signin"
         data-type="standard"
         data-shape="rectangular"
         data-theme="outline"
         data-text="signin_with"
         data-size="large"
         data-logo_alignment="left">
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'GoogleLogin',
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const clientId = '601531086236-osbftm6td30asjhq2nefff6jlvkjgqi6.apps.googleusercontent.com'

    const showError = (message) => {
      ElMessage({
        message: message || '登录失败，请重试',
        type: 'error',
        duration: 5000
      })
    }

    const handleCredentialResponse = async (response) => {
      console.log('Google response:', response)
      try {
        const result = await axios.post('/api/auth/google/', {
          access_token: response.credential,
          token_type: 'id_token'
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json'
          }
        })

        console.log('Login response:', result.data)

        if (result.data.token) {
          localStorage.setItem('token', result.data.token)
          localStorage.setItem('user', JSON.stringify(result.data.user))
          
          await authStore.setUser(result.data.user)
          await authStore.setToken(result.data.token)
          
          axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`
          
          if (result.data.user.is_staff) {
            router.push('/dashboard')
          } else {
            router.push('/customer')
          }
        }
      } catch (error) {
        console.error('Google login error:', error)
        if (error.response) {
          console.error('Error response:', error.response.data)
          const errorMessage = error.response.data?.error || error.response.data?.message || '登录失败，请重试'
          showError(errorMessage)
        } else {
          showError('网络错误，请检查网络连接')
        }
      }
    }

    onMounted(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      
      window.handleCredentialResponse = handleCredentialResponse
      
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    })

    return {
      clientId
    }
  }
})
</script>

<style scoped>
.google-login {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style> 