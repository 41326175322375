<template>
  <div class="login-container">
    <h2>登录</h2>
    <GoogleLogin />
  </div>
</template>

<script>
import GoogleLogin from '@/components/GoogleLogin.vue'

export default {
  name: 'UserLogin',
  components: {
    GoogleLogin
  }
}
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
}
</style> 